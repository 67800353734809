import gql from 'graphql-tag'

export const PUT_ONLINE_PLACEMENT_TEST = gql`
  mutation PutOnlinePlacementTest($OnlinePlacmentTestInput: [OnlinePlacmentTestInput!]!) {
    putOnlinePlacementTest(OnlinePlacmentTestInput: $OnlinePlacmentTestInput) {
      Success
      StatusCode
    }
  }
`
