import React, { useState } from 'react'
import { Container } from '../style'
import { MessageWrapper, StyledPlacementTestMessage, Title } from './style'
import { useIntl } from 'react-intl'
import { LoadingModal, WarningModal } from '@components/Modal'
import ImagePlacementTest from '@berlitzplatforms/micro.image.placement-test'
import { FormattedMessage } from 'react-intl'
import Spacer from '@berlitz/spacer'
import RetakeOPT from './RetakeOPT'
import TakeLevel1 from './TakeLevel1'
import LevelSelector from './LevelSelector'
import StartButton from './StartButton'
import useOnlinePlacementTest from '@hooks/useOnlinePlacementTest'

const OPTPicker: React.FC<{ programId?: string; registrationId?: string; isBOD1?: boolean }> = ({
  programId,
  registrationId,
  isBOD1,
}) => {
  const intl = useIntl()
  const [assessmentId, setAssessmentId] = useState<string>()
  const [OPTModal, setOPTModal] = React.useState(false)
  const [openErrorModal, setOpenErrorModal] = React.useState(false)
  const [OPTModalTitle, setOPTModalTitle] = React.useState('Checking registration...')

  const { data } = useOnlinePlacementTest(registrationId ?? '')

  const hasAsessmentHigherThanLevel1 = data?.getOnlinePlacementTest?.some((item) => {
    const level = parseInt(item?.OnlinePlacementTestInfo?.Level ?? '')
    if (!isNaN(level)) {
      return level >= 1
    }
    return false
  })

  let id = 'Previous placement scores'
  if (data?.getOnlinePlacementTest?.length === 1) {
    id = data?.getOnlinePlacementTest[0]?.OnlinePlacementTestInfo?.AssessmentId
  } else if (assessmentId) {
    id = assessmentId
  }

  return (
    <Container hideButtons={isBOD1}>
      <StyledPlacementTestMessage imgMaxHeight="256.135px">
        <ImagePlacementTest />
        <MessageWrapper>
          <Title>
            <FormattedMessage id="Thank you for completing your placement test" />
          </Title>
          <StartButton
            registrationId={registrationId}
            assessmentId={assessmentId}
            setOPTModal={(val) => {
              setOPTModal(val)
              setOPTModalTitle('Loading...')
            }}
            setOpenErrorModal={(val) => {
              setOpenErrorModal(val)
            }}
          />
          <Spacer size="lg" />
          <Spacer size="lg" />
          <RetakeOPT
            registrationId={registrationId}
            programId={programId}
            setOPTModal={(val) => {
              setOPTModal(val)
              setOPTModalTitle('Checking registration...')
            }}
          />

          {!hasAsessmentHigherThanLevel1 && (
            <TakeLevel1
              registrationId={registrationId}
              programId={programId}
              setOPTModal={(val) => {
                setOPTModal(val)
                setOPTModalTitle('Loading...')
              }}
              setOpenErrorModal={(val) => {
                setOpenErrorModal(val)
              }}
            />
          )}

          <LevelSelector
            registrationId={registrationId}
            levelSelectorValue={id}
            onLevelSelectorChange={(value) => {
              setAssessmentId(value)
            }}
          />
        </MessageWrapper>
      </StyledPlacementTestMessage>
      <LoadingModal
        open={OPTModal}
        onClose={() => {}}
        title={`${intl.formatMessage({
          id: OPTModalTitle,
        })}`}
      />
      <WarningModal open={openErrorModal} onClose={() => setOpenErrorModal(false)} anApiError noButtons />
    </Container>
  )
}

export default OPTPicker
