import React from 'react'
import ContentLoader from 'react-content-loader'
import { StyledPlacementTestMessage } from './style'

const Loader = () => (
  <StyledPlacementTestMessage>
    <div>
      <ContentLoader speed={2} width={230} height={150} viewBox="0 0 230 150" style={{ height: '247px' }}>
        <rect x="0" y="30" rx="0" ry="0" width="230" height="90" />
      </ContentLoader>
    </div>

    <div>
      <ContentLoader speed={2} width={300} height={150} viewBox="0 0 300 150" style={{ height: '247px' }}>
        <rect x="20" y="30" rx="0" ry="0" width="300" height="12" />
        <rect x="20" y="50" rx="0" ry="0" width="300" height="12" />
        <rect x="20" y="70" rx="0" ry="0" width="300" height="12" />
        <rect x="20" y="90" rx="0" ry="0" width="300" height="12" />
        <rect x="20" y="110" rx="0" ry="0" width="300" height="12" />
      </ContentLoader>
    </div>
  </StyledPlacementTestMessage>
)

export default Loader
