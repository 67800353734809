import React from 'react'
import { Message, ActionLink } from './style'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import PortalStudentProfile from '@classes/StudentProfile'
import { useMutation } from '@apollo/react-hooks'
import { POST_LEVEL_CHANGE } from '@mutations/levelChange'

interface LevelChangeResponse {
  postLevelChange: {
    Success: boolean
    StatusCode: number
  }
}

interface LevelChangeInput {
  RegistrationId: string
  Level: string
  Origin: string
  StartLevel1WithoutTest: boolean
}

interface LevelChangePayload {
  LevelChangeInput: LevelChangeInput[]
}

interface TakeLevel1OPTProps {
  setOPTModal: (val: boolean) => void
  setOpenErrorModal: (val: boolean) => void
  programId?: string
  registrationId?: string
}

const TakeLevel1: React.FC<TakeLevel1OPTProps> = ({ setOPTModal, setOpenErrorModal, programId, registrationId }) => {
  const portalStudentInfo = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo, dashboard?.activeMaterial ?? '')
  )

  const [postLevelChange] = useMutation<LevelChangeResponse, LevelChangePayload>(POST_LEVEL_CHANGE, {
    onCompleted: (data) => {
      if (data?.postLevelChange?.Success) {
        localStorage.setItem('activeOPT', '')
        window.location.reload()
      }
    },
    onError: () => {
      setOPTModal(false)
      setTimeout(() => {
        setOpenErrorModal(true)
      })
    },
  })

  return (
    <Message>
      <ActionLink
        role="button"
        onClick={() => {
          // show modal
          setOPTModal(true)

          let regId = registrationId
          if (!registrationId && programId) {
            const registration = portalStudentInfo.getRegistrationByProgramId(programId)
            regId = registration?.RegistrationInfo?.RegistrationId
          }
          const params = {
            RegistrationId: regId ?? '',
            Level: '1',
            Origin: 'Portal',
            StartLevel1WithoutTest: true,
          }

          postLevelChange({
            variables: {
              LevelChangeInput: [params],
            },
          })
        }}
      >
        <FormattedMessage id="I would like to start at Level 1" />
      </ActionLink>
    </Message>
  )
}

export default TakeLevel1
