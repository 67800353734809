import styled from 'styled-components'
import BerlitzButton from '@berlitzplatforms/micro.ui.button'

export const Button = styled(BerlitzButton)`
  ${({ theme }) => `
    padding: ${theme.space.xs} ${theme.space.md};
  `}
`

export const StyledPlacementTestMessage = styled.div<{ imgMaxHeight: string }>`
  display: flex;
  flex-flow: row;
  row-gap: ${({ theme }) => theme.space.lg};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;

  svg {
    max-width: 100%;
    max-height: ${({ imgMaxHeight }) => imgMaxHeight};
  }
`

export const MessageWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-flow: column;
`

export const Title = styled.div`
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.73px;
  text-align: left;
  ${({ theme }) => `
        font-size: ${theme.fontSize.md};
        color: ${theme.colors.brandSupporting01};
        font-weight: ${theme.fontWeight.bold};
        margin-bottom: ${theme.space.sm};
    `}
`

export const Message = styled.div`
  max-width: 343px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.56px;
  text-align: left;
  ${({ theme }) => `
        font-size: ${theme.fontSize.xs};
        color: ${theme.colors.brandSupporting01};
        margin-bottom: ${theme.space.md};
    `}

  a {
    text-decoration: none;
  }

  span {
    font-weight: 500;
    font-size: 14px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & > button:first-child {
    margin-bottom: 8px;
  }

  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.sm}) {
        flex-direction: row;
        justify-content: space-between;
        & > button:first-child {
            margin-right: 8px;
            margin-bottom: 0px;
        }
    }
  `}
`

export const ActionLink = styled.a`
  cursor: pointer;
  user-select: none;
`
