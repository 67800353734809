import React from 'react'
import { Button, Message } from './style'
import { FormattedMessage } from 'react-intl'
import useOnlinePlacementTest from '@hooks/useOnlinePlacementTest'
import { PUT_ONLINE_PLACEMENT_TEST } from '@mutations/onlinePlacementTest'
import { useMutation } from '@apollo/react-hooks'

interface OnlinePlacementTestResponse {
  putOnlinePlacementTest: {
    Success: boolean
    StatusCode: number
  }
}

interface OnlinePlacementTestInfo {
  LevelConfirmed: boolean
  AssessmentId: string
  Origin: string
}

interface OnlinePlacmentTestInput {
  OnlinePlacementTestInfo: OnlinePlacementTestInfo
}

interface OnlinePlacmentTestPayload {
  OnlinePlacmentTestInput: OnlinePlacmentTestInput[]
}

interface StartButtonProps {
  setOPTModal: (val: boolean) => void
  setOpenErrorModal: (val: boolean) => void
  registrationId?: string
  assessmentId?: string
}

const StartButton: React.FC<StartButtonProps> = ({ setOPTModal, setOpenErrorModal, registrationId, assessmentId }) => {
  const [putOnlinePlacementTest] = useMutation<OnlinePlacementTestResponse, OnlinePlacmentTestPayload>(
    PUT_ONLINE_PLACEMENT_TEST,
    {
      onCompleted: (data) => {
        if (data?.putOnlinePlacementTest?.Success) {
          localStorage.setItem('activeOPT', '')
          window.location.reload()
        }
      },
      onError: () => {
        setOPTModal(false)
        setTimeout(() => {
          setOpenErrorModal(true)
        })
      },
    }
  )

  const { data } = useOnlinePlacementTest(registrationId ?? '')
  const highestLevel = data?.getOnlinePlacementTest?.reduce((acc, item) => {
    if (acc.OnlinePlacementTestInfo?.Level < item.OnlinePlacementTestInfo?.Level) {
      acc = item
    }

    return acc
  })

  const opt = data?.getOnlinePlacementTest?.find((opt) => opt.OnlinePlacementTestInfo?.AssessmentId === assessmentId)
  const level = assessmentId ? opt?.OnlinePlacementTestInfo?.Level : highestLevel?.OnlinePlacementTestInfo?.Level

  return (
    <>
      <Message>
        <FormattedMessage
          id="Based on your score, it's recommended that you start on Level {level}."
          defaultMessage="Based on your score, it's recommended that you start on Level {level}."
          values={{ level }}
        />
      </Message>
      <Button
        onClick={() => {
          setOPTModal(true)
          const AssessmentId = assessmentId ?? highestLevel?.OnlinePlacementTestInfo?.AssessmentId
          if (AssessmentId) {
            const params = {
              OnlinePlacementTestInfo: {
                AssessmentId,
                LevelConfirmed: true,
                Origin: 'Portal',
              },
            }

            putOnlinePlacementTest({
              variables: {
                OnlinePlacmentTestInput: [params],
              },
            })
          }
        }}
      >
        <FormattedMessage id="Start Level {level}" defaultMessage="Start Level {level}" values={{ level }} />
      </Button>
    </>
  )
}

export default StartButton
