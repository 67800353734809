import React from 'react'
import { Container } from './style'
import PortalStudentProfile from '@classes/StudentProfile'
import { StoreProps } from '@interfaces/StoreState'
import { useSelector } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import Loader from './Loader'
import DefaultOPT from './DefaultOPT'
import OPTPicker from './OPTPicker'
import useOnlinePlacementTest from '@hooks/useOnlinePlacementTest'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'

const OnlinePlacementTest: React.FC<{ programId?: string; registrationId?: string; isBOD1?: boolean }> = ({
  programId,
  registrationId,
  isBOD1,
}) => {
  const portalStudentInfo = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo, dashboard?.activeMaterial ?? '')
  )

  let regId = registrationId
  if (!regId && programId) {
    const registration = portalStudentInfo.getRegistrationByProgramId(programId)
    regId = registration?.RegistrationInfo?.RegistrationId
  }
  const { loading: PlacementTestLoading, data: PlacementTest } = useOnlinePlacementTest(regId ?? '')

  const hasOPT = PlacementTest?.getOnlinePlacementTest?.length !== 0
  const loading = isEmpty(PlacementTest) || PlacementTestLoading

  const { state } = usePortalFeatures({
    configId: 'flag::OPT-AssessmentUI',
  })

  /**
   * if PlacementTest is
   * then display the PlacementTestSelector component
   * otherwise display Default component
   */
  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  if (state.allowed && hasOPT) {
    return <OPTPicker key={registrationId} programId={programId} registrationId={registrationId} isBOD1={isBOD1} />
  }

  return <DefaultOPT programId={programId} registrationId={registrationId} isBOD1={isBOD1} />
}

export default OnlinePlacementTest
