import gql from 'graphql-tag'

export const GET_ONLINE_PLACEMENT_TEST = gql`
  query GetOnlinePlacementTest($registrationId: String!) {
    getOnlinePlacementTest(registrationId: $registrationId) {
      OnlinePlacementTestInfo {
        Level
        AssessmentId
        AssessmentDate
      }
    }
  }
`
