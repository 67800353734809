import React, { useEffect } from 'react'
import { Message, ActionLink } from './style'
import { FormattedMessage } from 'react-intl'
import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import { GetUserProfile } from '@interfaces/UserProfile'
import { GET_USER_PROFILE } from '@queries/userProfile'
import { PROFILE_TYPE } from '@utils/constants'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import PortalStudentProfile from '@classes/StudentProfile'
import COPT from '@classes/OnlinePlacemenTest'

interface RetakeOPTProps {
  setOPTModal: (val: boolean) => void
  programId?: string
  registrationId?: string
}

const RetakeOPT: React.FC<RetakeOPTProps> = ({ setOPTModal, programId, registrationId }) => {
  const [getUserProfile, { data, loading }] = useCustomLazyQuery<GetUserProfile>(GET_USER_PROFILE)
  const portalStudentInfo = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo, dashboard?.activeMaterial ?? '')
  )

  /**
   * When onClick is triggered, We need to validate if the student is still required to take the OPT
   * (will be calculated base from the recent data)
   */
  const optDetails = (value?: GetUserProfile) => {
    if (value?.getUserProfile?.StudentProfile?.StudentProfileInfo) {
      if (programId) {
        const profile = new PortalStudentProfile(value?.getUserProfile?.StudentProfile?.StudentProfileInfo)
        const opt = new COPT(profile.student, undefined, profile.Programs[programId])
        return {
          isRequired: Boolean(opt?.IsRequired),
          url: opt.URL || '',
          activeId: programId,
        }
      } else {
        const profile2 = new PortalStudentProfile(value?.getUserProfile?.StudentProfile?.StudentProfileInfo)
        const registration = profile2?.student?.RegistrationWrappers?.find(
          ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === registrationId
        )
        const opt2 = new COPT(profile2.student, registration)
        return {
          isRequired: Boolean(opt2?.IsRequired),
          url: opt2.URL || '',
          activeId: registration?.RegistrationInfo?.Materials?.[0]?.LPID || '',
        }
      }
    }

    return {
      isRequired: false,
      url: '',
    }
  }

  /**
   * We are using useEffect here, since we need to trigger an external function
   * window.open
   * (will be calculated after rendering the component)
   */
  useEffect(() => {
    if (data && !loading) {
      const opt = optDetails(data)
      if (!opt?.isRequired) {
        localStorage.setItem('activeOPT', opt?.activeId || '')
        window.location.reload()
      } else {
        window.open(opt?.url, '_blank')
        setOPTModal(false)
      }
    }
  }, [data, loading])

  return (
    <Message>
      <ActionLink
        role="button"
        onClick={() => {
          // show modal
          setOPTModal(true)
          /**
           * 1. Show loading modal
           * 2. Refetch student profile
           */

          getUserProfile({
            Type: PROFILE_TYPE.STUDENT,
            Id: portalStudentInfo?.SFId,
          })
        }}
      >
        <FormattedMessage id="I would like to retake the Placement Test" />
      </ActionLink>
    </Message>
  )
}

export default RetakeOPT
