import React from 'react'
import Select from '../../../components/Select'
import useOnlinePlacementTest, { OnlinePlacmentTest } from '@hooks/useOnlinePlacementTest'
import { orderBy } from 'lodash'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { StoreProps } from '@interfaces/StoreState'
import { useSelector } from 'react-redux'

interface LevelSelectorProps {
  registrationId?: string
  levelSelectorValue?: string
  onLevelSelectorChange: (val: string) => void
}

const LevelSelector: React.FC<LevelSelectorProps> = ({ registrationId, levelSelectorValue, onLevelSelectorChange }) => {
  const intl = useIntl()
  const { data } = useOnlinePlacementTest(registrationId ?? '')
  const profile = useSelector(({ userProfile }: StoreProps) => userProfile.info)

  const options = orderBy(data?.getOnlinePlacementTest, ['OnlinePlacementTestInfo.Level'], ['desc'])
    .reduce((acc, item) => {
      const opt = acc.find((o) => o.OnlinePlacementTestInfo?.Level === item.OnlinePlacementTestInfo?.Level)
      if (!opt) {
        acc.push(item)
      }
      return acc
    }, [] as OnlinePlacmentTest[])
    .map((opt) => {
      const date = moment.tz(opt?.OnlinePlacementTestInfo?.AssessmentDate, profile?.Timezone).format('LL')
      const info = `Level ${opt?.OnlinePlacementTestInfo?.Level} - ${date}`
      const value = opt?.OnlinePlacementTestInfo?.AssessmentId
      return {
        value,
        id: value,
        label: info,
      }
    })
    .slice(0, 2)

  return (
    <>
      {options && options?.length > 0 && (
        <Select
          label={intl.formatMessage({ id: 'Use a previous score to start from' })}
          value={levelSelectorValue}
          name="level"
          onChange={(e: string) => {
            onLevelSelectorChange(e)
          }}
          options={options}
          disableMargin
          responsive
        />
      )}
    </>
  )
}

export default LevelSelector
