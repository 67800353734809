import { useQuery } from '@apollo/react-hooks'
import { GET_ONLINE_PLACEMENT_TEST } from '@queries/onlinePlacementTest'

export interface OnlinePlacmentTestInfo {
  Level: string
  AssessmentId: string
  AssessmentDate: string
}

export interface OnlinePlacmentTest {
  OnlinePlacementTestInfo: OnlinePlacmentTestInfo
}

const useOnlinePlacementTest = (id: string) => {
  return useQuery<{ getOnlinePlacementTest: OnlinePlacmentTest[] }, { registrationId: string }>(
    GET_ONLINE_PLACEMENT_TEST,
    {
      /** skip - do net execute if id is undefined */
      skip: !Boolean(id),
      variables: {
        registrationId: id ?? '',
      },
    }
  )
}

export default useOnlinePlacementTest
